import { bitrixComponent } from "./bitrix-component";
import Popup from "../components/popup";
class Forms {
    constructor() {
        this.siteKey = "6LdfdponAAAAAHC3yjV-R4Pk6O99nyBK8QDo_-Fr";
        this.component = "exmetal:forms";
        this.preloader = document.querySelector(".preloader_second");
        this.containerSuccessMessage = document.querySelector(".popup_success-message");
        this.popupSuccessMessage = new Popup(document.querySelector(".popup_success-message"));
        const forms = Array.from(document.querySelectorAll("form:not(.search-input)"));
        forms.forEach((form) => {
            this.showLabelFileInput(form);
            this.multiselect(form);
            form.onsubmit = () => {
                if (this.validateFile(form))
                    this.onSubmit(form);
                return false;
            };
        });
    }
    async onSubmit(form) {
        const formData = new FormData(form);
        const action = formData.get("action");
        if (!action)
            return false;
        this.preloader.classList.remove("_hidden");
        const token = await this.getCaptchaToken(action);
        if (token)
            formData.append("token", token);
        bitrixComponent(this.component, String(action), formData).then((response) => {
            if (response.status === "success") {
                this.handleResponseSuccess(response);
            }
            else if (response.status === "error") {
                this.handleResponseError(response);
            }
            this.preloader.classList.add("_hidden");
        });
        /* //@ts-ignore
        BX.ajax
            .runComponentAction("exmetal:forms", action, {
                mode: "ajax",
                data: new FormData(form)
            })
            .then(function (response) {});*/
        return false;
    }
    validateFile(form) {
        const inputFile = form.querySelector(".input-group--file input[type=file]");
        if (inputFile && inputFile.dataset?.required == "true") {
            if (!inputFile.value) {
                form.querySelector(".input-group--file__error").textContent =
                    "Обязательно прикрепите файл";
                form.querySelector(".input-group--file__error").style.display =
                    "block";
                return false;
            }
            else {
                form.querySelector(".input-group--file__error").textContent =
                    "";
                form.querySelector(".input-group--file__error").style.display =
                    "none";
            }
        }
        return true;
    }
    showLabelFileInput(form) {
        const _this = this;
        const inputFile = form.querySelector(".input-group--file input[type=file]");
        if (inputFile) {
            inputFile.onchange = function () {
                let file = form.querySelector("#form-file").files[0];
                form.querySelector(".input-group--file__name").textContent =
                    file ? file.name : "";
                _this.validateFile(form);
            };
        }
    }
    multiselect(form) {
        const inputsMultiselect = Array.from(form.querySelectorAll(".input-multiselect"));
        inputsMultiselect.forEach((container) => {
            const input = container.querySelector("input");
            const items = Array.from(container.querySelectorAll(".input-multiselect--item"));
            items.forEach((item) => {
                item.addEventListener("click", () => {
                    if (item.classList.contains("input-multiselect--item_selected")) {
                        item.classList.remove("input-multiselect--item_selected");
                    }
                    else {
                        item.classList.add("input-multiselect--item_selected");
                    }
                    const values = [];
                    Array.from(container.querySelectorAll(".input-multiselect--item_selected")).forEach((selected) => {
                        values.push(selected.dataset.value);
                    });
                    input.value = values.join("|");
                });
            });
        });
    }
    handleResponseError(response) { }
    handleResponseSuccess(response) {
        this.containerSuccessMessage.querySelector(".popup--title").textContent = response.data?.messageTitle;
        this.containerSuccessMessage.querySelector(".popup--text").textContent =
            response.data?.messageText;
        Popup.closeAll();
        this.popupSuccessMessage.open();
    }
    async getCaptchaToken(action) {
        await new Promise((resolve) => {
            // @ts-ignore
            grecaptcha.ready(resolve);
        });
        try {
            return await grecaptcha.execute(this.siteKey, {
                action: action
            });
        }
        catch (e) {
            return "";
        }
    }
}
export default Forms;
