import calcMock from "./calulator-mock";
import Swiper from "swiper";
import Popup from "../popup";
class Calculator {
    constructor() {
        this.basket = [];
        this.container = document.querySelector(".price-list--calc");
        if (!this.container)
            return;
        this.tabs = document.querySelector(".price-list--tabs");
        this.tabsSwiper = this.tabs.querySelector(".swiper-wrapper");
        this.form = this.container.querySelector("form");
        this.checkboxDelivery = this.form.querySelector("#with_delivery");
        this.selectCategory = this.form.querySelector("#category_id");
        this.selectMetalName = this.form.querySelector("#metal_name");
        this.inputQuantity = this.form.querySelector("#quantity");
        this.selectUnit = this.form.querySelector("#unit_type");
        this.priceKilogram = this.form.querySelector(".item-price--kilogram");
        this.priceTon = this.form.querySelector(".item-price--ton");
        this.btnAdd = this.form.querySelector(".button--calc-add");
        this.btnSend = this.form.querySelector(".button--calc-send");
        this.calcAmount = this.form.querySelector(".calc-amount .calc--value");
        this.calcTotal = this.form.querySelector(".calc-total .calc--value");
        this.metals = this.container.querySelector(".metals");
        this.popupCalculator = new Popup(document.querySelector(".popup_calculator"));
        this.bindEvents();
        this.init();
    }
    init() {
        this.setPriceListByCurrentCity();
        this.setCategories();
    }
    bindEvents() {
        this.selectCategory.onchange = () => this.onSetCategory();
        this.selectMetalName.onchange = () => this.onSetMetal();
        this.inputQuantity.onchange = () => this.onSetQuantity();
        this.inputQuantity.onkeyup = () => this.onSetQuantity();
        this.selectUnit.onchange = () => this.onSetUnitType();
        this.checkboxDelivery.onchange = () => this.onSetDelivery();
        this.btnAdd.onclick = (e) => {
            e.preventDefault();
            this.basketAdd();
        };
        this.btnSend.onclick = (e) => {
            e.preventDefault();
            this.sendResult();
        };
    }
    getPriceList() {
        return calcMock.priceList;
    }
    setPriceListByCurrentCity( /*id*/) {
        this.priceList = this.getPriceList();
    }
    setCategories() {
        this.basketItem = {};
        this.priceItem = {};
        this.priceList
            .filter((v, i, a) => a.findIndex((v2) => v2.categoryId === v.categoryId) === i)
            .map((item) => {
            const option = document.createElement("option");
            option.value = String(item.categoryId);
            option.innerText = item.categoryName;
            this.selectCategory.appendChild(option);
            const tab = document.createElement("a");
            tab.classList.add("price-list--tab");
            tab.href = "#tr_" + String(item.categoryId);
            tab.innerHTML = item.categoryName;
            // const swiperSlide = document.createElement("div");
            // swiperSlide.classList.add("swiper-slide");
            // swiperSlide.appendChild(tab);
            // this.tabsSwiper.appendChild(swiperSlide);
        });
        this.onAfterInit();
    }
    onAfterInit() {
        new Swiper(".price-list--tabs", {
            loop: false,
            grabCursor: true,
            slidesPerView: "auto",
            spaceBetween: 10
        });
    }
    onSetCategory() {
        this.basketItem = {};
        this.priceItem = {};
        this.selectMetalName
            .querySelectorAll('option:not([value=""])')
            .forEach((item) => item.remove());
        this.priceList
            .filter((item) => {
            return item.categoryId === Number(this.selectCategory.value);
        })
            .map((item) => {
            const option = document.createElement("option");
            option.value = String(item.id);
            option.innerText = item.name;
            this.selectMetalName.appendChild(option);
        });
        this.inputQuantity.value = "";
        this.basketItem.quantity = 0;
        this.basketItem.productId = 0;
        this.basketItem.id = 0;
        this.onChange();
    }
    onSetMetal() {
        this.priceItem = this.priceList.filter((item) => item.id === Number(this.selectMetalName.value))[0];
        if (typeof this.priceItem !== "undefined") {
            this.basketItem.id = this.getRandomInt(10000, 99998);
            this.basketItem.productId = this.priceItem.id;
        }
        else {
            this.basketItem.id = 0;
            this.basketItem.productId = 0;
            this.priceItem = {};
            this.inputQuantity.value = "";
            this.basketItem.quantity = 0;
        }
        this.onChange();
    }
    onSetQuantity() {
        this.basketItem.quantity = Number(this.inputQuantity.value);
        this.onChange();
    }
    onSetUnitType() {
        this.basketItem.unitType = this.selectUnit.value;
        this.onChange();
    }
    onSetDelivery() {
        console.log(this.checkboxDelivery.checked);
        this.onChange();
    }
    onChange() {
        this.setPrice();
        this.setAmount();
        this.setTotal();
    }
    getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    setPrice() {
        const item = Object.keys(this.priceItem).length
            ? this.priceItem
            : { pricePerKilogram: 0, pricePerTon: 0, currency: "RUB" };
        this.priceKilogram.innerHTML =
            `<span>${item.pricePerKilogram}</span>` +
                ` ${this.getCurrency()} / кг`;
        this.priceTon.innerHTML =
            `<span>${item.pricePerTon}</span>` + ` ${this.getCurrency()} / т`;
    }
    setAmount() {
        const item = typeof this.priceItem !== "undefined"
            ? this.priceItem
            : { pricePerKilogram: 0, pricePerTon: 0, currency: "RUB" };
        this.basketItem.unitType = this.selectUnit.value;
        const priceUnitType = this.basketItem.unitType === "ton"
            ? item.pricePerTon
            : item.pricePerKilogram;
        const amount = (priceUnitType ?? 0) * (this.basketItem.quantity ?? 0);
        this.basketItem.amount = amount;
        this.calcAmount.innerHTML = `<span>${amount}</span> 
            ${this.getCurrency()}`;
        this.buttonAddState(amount > 0);
    }
    setTotal() {
        let amount = this.basketItem.amount;
        this.basket.forEach((item) => {
            amount += item.amount;
        });
        this.calcTotal.innerHTML = `<span>${amount}</span> ${this.getCurrency()}`;
    }
    buttonAddState(active) {
        if (active)
            this.btnAdd.classList.remove("disabled");
        else
            this.btnAdd.classList.add("disabled");
    }
    basketAdd() {
        this.basketItem.productName = this.priceItem.name;
        this.basketItem.categoryName = this.priceItem.categoryName;
        this.basketItem.currency = this.priceItem.currency;
        this.basket.push(this.basketItem);
        this.metals.appendChild(this.getMetalItem(this.basketItem));
        this.cleanForm();
        console.log(this.basket);
    }
    basketRemove(id) {
        this.basket = this.basket.filter((item) => item.id !== id);
        document.querySelector("#item__" + id).remove();
        this.onChange();
    }
    basketClean() {
        for (let k in this.basket) {
            document.querySelector("#item__" + this.basket[k]["id"]).remove();
            delete this.basket[k];
        }
        this.cleanForm();
    }
    sendResult() {
        console.log(this.basket);
        this.popupCalculator.open();
        this.basketClean();
    }
    getCurrency() {
        return this.priceItem.currency === "KZT" ? "₸" : "₽";
    }
    cleanForm() {
        this.basketItem = {};
        this.priceItem = {};
        this.selectCategory.value = "";
        this.selectCategory.querySelector('option[value=""]').selected = true;
        this.selectMetalName.value = "";
        this.selectMetalName
            .querySelectorAll('option:not([value=""])')
            .forEach((item) => item.remove());
        this.inputQuantity.value = "";
        this.onChange();
    }
    getMetalItem(item) {
        const itemContainer = document.createElement("div");
        const quantity = `${item.quantity} ${item.unitType === "ton" ? "т" : "кг"}`;
        const price = `${item.amount} ${this.getCurrency()}`;
        itemContainer.id = "item__" + item.id;
        itemContainer.classList.add("metals--item");
        itemContainer.innerHTML = `<div class="metals--item-left">
                <div class="metals--item-name">${this.priceItem.name}</div>
                <div class="metals--item-quantity">${quantity}</div>
            </div>
            <div class="metals--item-right">
                <div class="metals--item-price">${price}</div>
                <div class="metals--item-delete">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.5 16.7736L16.7667 17.5L6.5 7.32977L7.23333 6.60333L17.5 16.7736Z" fill="#444744"></path>
                        <path d="M17.5 7.22644L16.7667 6.5L6.5 16.6702L7.23334 17.3967L17.5 7.22644Z" fill="#444744"></path>
                    </svg>
                </div>
            </div>`;
        itemContainer
            .querySelector(".metals--item-delete")
            .addEventListener("click", () => this.basketRemove(item.id));
        return itemContainer;
    }
}
export default Calculator;
