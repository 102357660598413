class Marque {
    constructor(selector, direction = 1) {
        //$(document).on('mouseover', this.itemsContainer, () => this.setSpeed(WatchSliderSpeed.HOVER))
        //$(document).on('mouseout', this.itemsContainer, () => this.setSpeed(WatchSliderSpeed.NORMAL))
        this.paused = false;
        this.itemsContainer = document.querySelector(selector);
        if (!this.itemsContainer)
            return;
        const items = this.itemsContainer.querySelector(".marque-items");
        this.carouselWidth = items.offsetWidth;
        this.direction = direction;
        if (!this.carouselWidth) {
            return;
        }
        const clonesQuantity = Math.ceil(window.outerWidth / this.carouselWidth) * 5;
        for (let i = 0; i < clonesQuantity; i++) {
            const newItem = items.cloneNode(true);
            newItem.classList.add("clone");
            this.itemsContainer.prepend(newItem);
        }
        this.resetSlider();
        this.play();
    }
    pause() {
        this.paused = true;
    }
    play() {
        this.paused = false;
        this.speed = 3;
        this.loop();
    }
    setSpeed(value) {
        this.speed = value;
    }
    loop() {
        if (this.paused) {
            return;
        }
        let offset = this.getOffset();
        // if (Math.abs(offset) >= Math.abs(this.carouselWidth)) {
        //     this.resetSlider();
        //     offset = this.getOffset();
        // }
        if (Math.abs(offset) >
            this.itemsContainer.offsetWidth - window.innerWidth) {
            this.resetSlider();
            offset = this.getOffset();
        }
        offset =
            this.direction === 1
                ? offset
                : this.direction * offset - Math.abs(this.carouselWidth);
        this.itemsContainer.style.transform = `translateX(${offset}px)`;
        this.time -= this.speed;
        requestAnimationFrame(this.loop.bind(this));
    }
    getOffset() {
        return this.time / 3.4;
    }
    resetSlider() {
        this.time = 1;
    }
}
export default Marque;
